import React from "react"
import { Link } from "gatsby"

import { rhythm } from "../utils/typography"
import Logo from "../../content/assets/logo-crafted-films.svg"
import StripFooter from "../../content/assets/footer.svg"
import Instagram from "../../content/assets/iconmonstr-instagram.svg"
import LinkedIn from "../../content/assets/iconmonstr-linkedin.svg"
import Vimeo from "../../content/assets/iconmonstr-vimeo.svg"
import IconMenu from "../../content/assets/icon-menu.svg"

const Layout = ({ title, children }) => {
  let handleClick = function (e) {
    let nav =
      typeof document !== `undefined` ? document.getElementById("nav") : null
    let target = e.target.closest("nav")
    if (target === nav) {
      target.classList.toggle("active")
    } else {
      return false
    }
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
      }}
    >
      <header
        style={{
          maxWidth: rhythm(44),
          marginLeft: `auto`,
          marginRight: `auto`,
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)} 0`,
        }}
      >
        <Link
          className="logo-mobile"
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <img src={Logo} title={title} alt={title} />
        </Link>
        <nav id="nav">
          <img
            src={IconMenu}
            title="Open menu"
            alt="Open menu"
            className="menu"
            onClick={handleClick}
          />
          <div
            style={{
              margin: 0,
              justifyContent: `flex-end`,
            }}
          >
            <Link to={`/`}>Home</Link>
            <Link
              to={`/about`}
              activeStyle={{ borderBottom: `1px solid black` }}
            >
              About
            </Link>
            <div style={{ margin: 0 }}>
              <Link
                to={`/portfolio`}
                activeStyle={{ borderBottom: `1px solid black` }}
              >
                Portfolio
              </Link>
              <div className="sub-menu">
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/portfolio/craft"
                >
                  Craft
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/portfolio/design"
                >
                  Design & Interiors
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/portfolio/gardening"
                >
                  Gardening
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/portfolio/food"
                >
                  Food
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/portfolio/drink"
                >
                  Drink
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/portfolio/history"
                >
                  History
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/portfolio/events"
                >
                  Events
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/portfolio/tech"
                >
                  Tech
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/portfolio/editorial"
                >
                  Editorial
                </Link>
              </div>
            </div>
          </div>
          <Link
            className="nav-logo"
            style={{
              boxShadow: `none`,
              color: `inherit`,
              marginTop: 0,
            }}
            to={`/`}
          >
            <img src={Logo} title={title} alt={title} className="logo-main" />
          </Link>
          <div
            style={{
              margin: 0,
              justifyContent: `flex-start`,
            }}
          >
            <div style={{ margin: 0 }}>
              <Link
                to={`/services`}
                activeStyle={{ borderBottom: `1px solid black` }}
              >
                Services
              </Link>
              <div className="sub-menu">
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/services/why-video"
                >
                  Why use video?
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/services/video-production"
                >
                  Video Production Services
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/services/video-content"
                >
                  Let's Get Visual
                </Link>
                <Link
                  style={{
                    display: `block`,
                  }}
                  to="/services/cost"
                >
                  How much will my video cost?
                </Link>
              </div>
            </div>
            <Link
              to={`/blog`}
              activeStyle={{ borderBottom: `1px solid black` }}
            >
              Blog
            </Link>
            <Link
              to={`/contact`}
              activeStyle={{ borderBottom: `1px solid black` }}
            >
              Contact
            </Link>
          </div>
        </nav>
      </header>
      <main>{children}</main>
      <footer style={{ margin: `3rem 0`, textAlign: `center` }}>
        <div style={{ margin: `0 0 2rem 0` }}>
          <p>
            Have you got a project in mind? I'd love to hear from you! <br />
            <span style={{ display: "block" }}>
              <a href="mailto:iris@crafted-films.co.uk">
                <strong>iris@crafted-films.co.uk</strong>
              </a>
            </span>
          </p>
        </div>
        <div className="footer-strip">
          <img src={StripFooter} title={title} alt={title} />
          <div className="social">
            <a
              href="https://vimeo.com/user8492383"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Vimeo} title="Vimeo" alt="Vimeo" />
            </a>
            <a
              href="https://www.linkedin.com/in/iris-gibson-151b91119"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LinkedIn} title="LinkedIn" alt="LinkedIn" />
            </a>
            <a
              href="https://www.instagram.com/crafted_films/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Instagram} title="Instagram" alt="Instagram" />
            </a>
          </div>
        </div>
        &copy; {new Date().getFullYear()} Crafted Films <br />
        <small>
          All rights reserved. No part of this website may be reproduced without
          permission.
        </small>
      </footer>
    </div>
  )
}

export default Layout
